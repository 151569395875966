.MuiList-root {
    border-radius: 0 0 8px 8px !important;
}

.MuiPopover-root {
    z-index: 1500 !important;
}

.MuiPaper-rounded {
    border-radius: 0 0 8px 8px !important;
}

.MuiPaper-elevation8 {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12) !important;
}